@import "src/iensoink/ui-components/scss/variables";

.ienso-radio-btn {
  position:relative;
  [type="radio"] ~ label {
    z-index:0;
     position:relative;
  }
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    top: 5px;
    left: 0;
    height: 28px;
    width: 28px;
    z-index: 1;
    cursor:pointer;
    opacity:0;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
    position: relative;
    padding-left: 50px;
    cursor: pointer;
    line-height: 28px;
    display: inline-block;
    color: $black;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 28px;
    height: 28px;
    border: 2px solid $main;
    border-radius: 100%;
    background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 14px;
    height: 14px;
    background: $main;
    position: absolute;
    top: 7px;
    left: 7px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.ienso-radio-btn.disabled {
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    border: 2px solid $gray3;
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    background: $gray3;
  }
}
