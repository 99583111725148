@import "src/iensoink/ui-components/scss/variables";


.ienso-button {

  button.modal-secondary {
    height: 30px;
    border: solid 1px $main !important;
    border-radius: 4px !important;
    color: $main !important;
    box-shadow: none;
    padding: 0;

  }

  button.modal-secondary.sm {
    width: 110px!important;
  }

  button.modal-secondary.lg {
    width: 170px!important;
  }

  button.modal-secondary, .modal-secondary-with-icon, .modal-secondary-icon-only {
    &:hover {
      box-shadow: 0 2px 7px 0 $gray !important;
    }

    &:disabled {
      background-color: $gray3 !important;
      color: $gray2 !important;
      box-shadow: none !important;
    }
  }
}
