@import "src/iensoink/ui-components/scss/styles";

.ienso-date-picker-wrapper {
  float: left;
  height: 33px !important;

  .DateInput {
    width: 75px;
  }

  #dateFrom {
    @extend .text-small-1;
    line-height: 14px;
    padding: 0;
    cursor: pointer;
  }

  #dateFrom::placeholder {
    color: $gray2  !important;
  }

  #dateTo {
    @extend .text-small-1;
    line-height: 14px;
    padding: 0;
    cursor: pointer;
  }

  #dateTo::placeholder {
    color: $gray2  !important;
  }

  .ienso-date-picker {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: solid 1px $gray2;
    margin: 0 0 0 15px;
    height: 33px;
    width: fit-content;
    padding: 0 10px;
    cursor: pointer;
  }

.ienso-date-picker:hover {
  border: solid 1px $black;
}

  .ienso-date-picker-icon {
    margin-left: 10px;
    width: 13px;
  }

}