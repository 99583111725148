@import "src/iensoink/ui-components/scss/variables";


.ienso-button {
  %main {
    height: 35px;
    box-shadow: none;
    background-color: $main;
    color: $white !important;
    border-radius: 6px;
  }

  button.main {
    @extend %main;
    min-width: 110px;
  }

  button.lg.main-with-icon{
    min-width: 230px;
    width: fit-content;
  }
  .main-with-icon {
    @extend %main;
    min-width: 130px;
    svg, img {
      margin-right: 10px;
      width: 16px;
      height: 17px;
      fill: $white;
      margin-top: -1px;
    }
  }

  .main-icon-only {
    width: 34px;
    height: 34px;
    padding: unset;
    border-radius: 6px;
    box-shadow: none;
    background-color: $main;

    svg, img {
      margin-right: 0;
      width: 12px;
      height: 12px;
      fill: $white;
    }

  }

  button.main, .main-with-icon, .main-icon-only {
    &:hover {
      box-shadow: 0 2px 7px 0 $gray !important;
    }

    &:disabled {
      background-color: $gray3 !important;
      color: $gray2 !important;
      box-shadow: none !important;

      svg, img {
        fill: $gray5;
      }
    }
  }
}
