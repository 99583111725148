@import "src/iensoink/ui-components/scss/variables";


html body .filter-by {
  float: left;
  height: 33px!important;

  .dropdown-toggle::after {
    right: 15px;
    position: absolute;
    top: 45%;
  }
  .dropdown-toggle:hover {
    color: $black!important;
  }
  display: flex;
  border: 1px solid $gray3;
  border-radius: 4px;
  margin: 0 8.5px 1vh 8.5px;
  min-width: unset;


  li {
    list-style-type: none;
    width: 100%;
    min-width: unset;

    .dropdown-menu-filter-by.show {
      overflow-x: hidden;
      overflow-y: auto;
      height: auto;
      max-height: 360px;
      min-height: 6vh;
      border-radius: 10px;
      box-shadow: 0 5px 10px 0 $gray3;
      background-color: $white;
      padding: 19px 15px 19px 6px;
      transform: translate3d(0.33333px, 40px, 0px) !important;


      a.dropdown-item {
        padding-left: 12px;
        padding-right: 26px;
      }
      
      a.dropdown-item:hover,
      a.dropdown-item:active {
        background-color: $offWhite;
        color: $black2;
      }
      .dropdown-item.disabled{
        color: $black2 !important;
      }
    }

    a {
      padding: 7px 30px 7px 20px;
      background-color: $white;
      border-radius: 4px;
    }

    .selected-title{
      font-weight: 500;
    }

  }
}
.selected-filter{
  gap: 5px!important;
  border: 1px solid $black!important;
  a {
    color: $black;
    font-weight: 500;
  }
}

.filter-by:hover {
  gap: 5px;
  border: 1px solid $black;
}
