@import "src/iensoink/ui-components/scss/variables";

.ienso-switch {
  margin-top: 4px;
  display: inline-block;

  label {
    height: 15px;
    width: 36px;
  }

  .c-switch-slider {
    background-color: #c0c1c4 !important;
    outline: none;
    box-shadow: none !important;
    border: none !important;
  }

  .c-switch-slider::before {
    background-color: #61656b;
    width: 18.8px !important;
    height: 18.8px !important;
    top: -2px !important;
    box-shadow: none !important;
    border: none !important;
  }

  input:focus {
    outline: none;
    box-shadow: none !important;
    border: none !important;
  }

  .c-switch-slider:focus ~ .c-switch-slider::before {
    box-shadow: none !important;
    border: none !important;
  }

}

.ienso-switch.checked{

  .c-switch-slider {
    background-color: $green2 !important;
    outline: none;

  }

  .c-switch-slider::before {
    background-color: $green;
    left: 6px !important;
    width: 18.8px !important;
    height: 18.8px !important;
  }
}


.ienso-switch.disabled{

  .c-switch-slider {
    background-color: $gray3 !important;
    outline: none;

  }

  .c-switch-slider::before {
    background-color: $gray2;
  }
}
