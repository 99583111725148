@import "src/iensoink/ui-components/scss/variables";


.tippy-arrow {
  color: $black;
  background-color: $black;
}

.tippy-content {
  background-color: $black;
  border-radius: 2px!important;
  font-size: 11px!important;
  color: $white;
}

.tooltip-text {
  width: fit-content !important;
  border-radius: 2px !important;
}

.ienso-tooltip{
  display: inline-block;
}
