@import "src/iensoink/ui-components/scss/styles";


.ienso-header-row-btn-search {
  height: 35px;
  width: 100%;

  &__wrapper {
    width: fit-content;
    float: right;

    &__search {
      display: inline-block;

      .ienso-input-group {
        width: 275px;
        margin: 0 20px;
      }
    }

    &__search-without-margin{
      display: inline-block;

      .ienso-input-group {
        width: 275px;
        margin: 0;
      }
    }

  }


}