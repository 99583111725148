@import "src/iensoink/ui-components/scss/variables";

.ienso-slider {
  .horizontal-slider {
    width: 136px;

    height: 2vh;
  }

  .example-thumb {
    cursor: pointer;
    position: absolute;
    z-index: 100;
    border-radius: 100%;
    display: block;
    left: -2px;
  }

  .example-thumb.active {
    background-color: grey;
  }

  .example-track {
    position: relative;
    background: #ddd;
  }

  .example-track.example-track-0 {
    background: $main;
    height: 4px!important;
    left: 8px!important;
    margin-right: 5px;
    border-radius: 100px;
  }

  .example-track.example-track-1 {
    background: $gray2;
    right: 8px!important;
    border-radius: 100px;
    margin-top: 1px;
  }

  .horizontal-slider .example-track {
    top: 25px;
    height: 1px;
  }

  .horizontal-slider .example-thumb {
    top: 17px;
    width: 18px;
    height: 18px;
    outline: none;
    line-height: 38px;


    background: $main;
    border: 5px solid $main;
  }

  .example-track.disabled {
    background: $gray5;
    margin-right: 5px;
    border-radius: 100px;
    cursor: default;
  }

  .example-thumb.disabled {
    position: absolute;
    z-index: 100;
    background: $gray5;
    border: 5px solid $gray5;
    border-radius: 100%;
    display: block;
    left: -2px;
    cursor: default;

  }


  .example-mark {
    width: 8px;
    height: 8px;
    border: 2px solid rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    border-radius: 50%;
    vertical-align: middle;
    margin: 0 calc(19px);
    bottom: calc(50% - 6px);
  }

  .example-mark.example-mark-active {
    border: 2px solid #00d4ff;
    background-color: rgb(255, 255, 255);
  }

  .example-mark.example-mark-completed {
    border: 2px solid rgb(255, 255, 255);
    background-color: #00d4ff;
  }
}
