@import "src/iensoink/ui-components/scss/variables";

.ienso-multi-select {
  .custom-multi-select {
    width: 100%;
    &__control{
      height: 33px;
      border: 1px solid $gray2;
    }
    &__control:active, :hover{
      border: 1px solid $gray2;
      box-shadow: none;
    }


    &__value-container,
    &__input-container:hover,
    &__indicator:hover,
    &__indicators:hover,
    &__dropdown-indicator:hover{
      box-shadow: none!important;
      border: none!important;

    }
    svg,svg:hover{
      border: none!important;
    }
    &__dropdown-indicator{
      height: 33px;
      margin-top: -8px;
    }
    &__indicator-separator{
      display: none;
    }
  }
}
