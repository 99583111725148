@import "src/iensoink/ui-components/scss/variables";


.ienso-select {
  height: 33px !important;
  display: inline-block;
  width: 100%;

  .dropdown-menu {
    width: 100%;
    margin-top: 3px;
  }

  .dropdown-toggle::after {
    right: 15px;
    position: absolute;
    top: 45%;
    color: $black;
  }

  border: 1px solid $gray3;
  border-radius: 4px;

  .show-placeholder {
    .dropdown-custom {
      color: $gray2;
    }
  }

  li {
    list-style-type: none;
    width: 100%;
    min-width: unset;

    .dropdown-menu-filter-by {
      max-height: 20vh;
      overflow-x: hidden;
      overflow-y: auto;
      min-height: 6vh;
      box-shadow: 0 10px 30px 0 rgba(107, 110, 128, 0.1);
      background-color: $white;
      padding: 18px 10px 20px;
      transform: translate3d(0.33333px, 40px, 0px) !important;

      .dropdown-item.disabled {
        color: unset !important;
      }
    }

    a {
      padding: 7px 30px 7px 10px;
      background-color: $white;
      border-radius: 4px;
      color: $black2;
    }

    a.dropdown-item:hover {
      background-color: $offWhite;
    }

    a.selected-item.disabled {
      color: $black2 !important;
    }

    a:active {
      background-color: $white;
      color: $black2;
    }
  }
}

.selected-filter {
  gap: 5px !important;
  border: 1px solid $black !important;

  a {
    color: $black;
    font-weight: 500;
  }
}

.filter-by:hover {
  gap: 5px;
  border: 1px solid $black;
}

.input-rounded-border-right {
  .ienso-select {
    width: calc(100% - 39px) !important;
  }
}


.ienso-select-wrapper:focus-within {
  .ienso-select {
    border-color: $gray !important;
  }

  .ienso-select-invalid {
    border-color: $red !important;
    .dropdown-custom{
      background-position: right calc(1.9em + 0.1875rem) center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e55353' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath strokeLinejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e55353' stroke='none'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
    
  }
}

.ienso-select-invalid {
  border-color: $red !important;
  .dropdown-custom{
    background-position: right calc(1.9em + 0.1875rem) center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e55353' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath strokeLinejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e55353' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  
}
