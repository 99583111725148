@import "src/iensoink/ui-components/scss/variables";

.ienso-color-selector {
  label {
    display: flex;
    align-items: flex-end;

    .ienso-tooltip {
      padding-left: 10px;
    }
  }
  input {
    border-radius: 5px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: none;
  }

  input[type="color" i] {
    padding: 0;
    background-color: $white;
    height: 100%;
    width: 100%;
    box-shadow: none !important;
    border: none;
    border-radius: 2px;
  }

  .form-control:disabled {
    background-color: $white;
    color: $gray
  }

  .input-group-text {
    padding: 0.175rem 0.375rem;
  }

  &__icon-container {
    background-color: #fff;
    width: 47px;

    .color-view-wrapper {
      height: 100%;
      width: 100%;

      .color-view {
        height: 100%;
        width: 100%;
        box-shadow: none !important;
        border: none;
        border-radius: 2px;
      }
    }

    .tippy-content {
      max-width: unset;
    }
  }

  .form-control.is-invalid {
    border-left: none;
  }

  .input-group-text-invalid {
    border-color: $red !important;
    border-right: none !important;
  }

  .form-control:focus {
    border: 1px solid $black;
    border-left: none !important;
    box-shadow: none;
  }

  .form-control.is-invalid {
    border-color: $red;
  }

  .input-group-text {
    width: 39px;
    height: 33px;
  }

  .input-group-prepend {
    width: 39px !important;
    height: 33px !important;
  }

  .input-group {
    input.form-control {
      transition: none;
      height: 33px !important;
    }
  }

  .form-group {
    label {
      font-size: 14px;
      color: $black2;
      margin-bottom: 13px!important;
    }
    margin-bottom: 21px!important;

  }
  .invalid-feedback{
    font-size: 13px!important;
    color: $red !important;

  }
}

.ienso-color-selector:not(.ienso-color-selector-disabled) {
  
  .input-group:hover .form-control {
      border-color: $black2;
    }


  .input-group:hover .input-group-prepend .input-group-text {
    border-color: $black2;
  }
}

.ienso-color-selector-disabled {
  input.form-control {
    background-color: $gray3!important;
    color: $gray2 !important;
  }

  input.color-view {
    opacity: 0.1;
  }
  .input-group-text {
    background-color: $gray3 !important;
  }
}

.ienso-color-selector:focus-within{
  input{
    border-color: $gray!important;
  }
  .input-group-text {
    border-color: $gray!important;

  }

}
