@import "src/iensoink/ui-components/scss/variables";

.table-search{
  .text-field-icon{
    margin: 0 auto;
    vertical-align: middle;
    svg{
      fill:$gray
    }
  }

  .form-group {
    margin-bottom: 0;
    .input-group {
      flex-wrap: unset;
      input{
        height: 35px!important;
        border-left: none;
      }

    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {

    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    height: 33px !important;
  }

  .ienso-input-group:focus-within{
    .input-group-prepend{
      border-right: 1px solid $black;
    }
  }
}



