@import "src/iensoink/ui-components/scss/variables";

.ienso-row-per-page {
  width: fit-content;
  height: 33px;
  &__title {
    color: $gray;
    margin: 0 5px;
  }
  &__drop:hover {
    gap: 5px;
    border: 1px solid $black;
  }
  &__drop {
    display: inline-block;
    width: 70px;
    height: 33px;

    .dropdown-toggle::after {
      position: absolute;
      top: 15px;
    }

    border: 1px solid $gray3;
    border-radius: 4px;


    li {
      list-style-type: none;
      width: 100%;

      .dropdown-menu-items-per-page {
        max-height: 360px;
        overflow-x: hidden;
        overflow-y: auto;
        min-height: 6vh;
        min-width: 70px;
        border-radius: 10px;
        box-shadow: 0 10px 30px 0 $gray3 !important;
        position: absolute;
        top: 10px !important;
  
        .selected-item {
          color: $black;
        }

        a:hover,
        a:active {
          background-color: $offWhite;
          color: $black2;
        }

        a {
          padding: 6.8px 25px 6.8px 19px;
        }
      }

      a {
        color: $black2;
        font-size: 14px !important;
      }

    }
  }
}

.ienso-row-per-page.float-right {
  float: right;
}
