@import "src/iensoink/ui-components/scss/variables";

.export-cvs{
  float: right;
  margin-right: 12px;
  svg,img{
    width: 14px!important;
    height: 14px!important;
    fill: $gray!important;
  }
}
