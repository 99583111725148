@import "variables";
@import "fonts";
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

html body {
  * {
    font-family: Rubik, serif;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $black;
  }

  .c-app {
    height: 100%;
    min-height: auto;
  }

  .c-body{
    background-color: #F5F9FC!important;
  }

  input::placeholder {
    font-size: 14px !important;
    color: $gray2 !important;
  }

  .card, .card-header {
    overflow: hidden;
    border-radius: 19px !important;
    border-bottom: none !important;
    padding-bottom: 0;
  }
}
