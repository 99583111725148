@import "src/iensoink/ui-components/scss/variables";

html body .ienso-filter {
  .ienso-table-filters.hidden-filter-title {
    margin-left: -8.5px;
  }

  .ienso-table-filters {
    width: fit-content;
    float: left;

    .reset-filters-btn {
      height: 33px;
      font-size: 14px !important;
      font-weight: 500 !important;
      color: $black !important;
      border: none !important;
      margin-left: 8.5px;
      width: 148px;
      box-shadow: none !important;

      svg {
        fill: $black;
        margin-right: 10px !important;
        width: 14px !important;
        height: 14px !important;
      }
    }

    .reset-filters-btn.disabled {
      color: $gray5 !important;
      border: none !important;
      font-weight: 400 !important;
      background-color: inherit !important;

      svg {
        fill: $gray5;
      }
    }

    .disabled-button-reset {

    }

    .filters-row {
      display: block;
      float: left;
      width: 100%;

      &__title {
        margin-right: 21.5px;
        opacity: 0.7;
        float: left;
        margin-top: 6px;
        color: $gray;
      }
    }
  }

  .filters-toggle, .filters-aside {
    display: none;
  }


  .filters-row {
    float: left;
  }

  .filters-toggle {
    &__btn {
      box-shadow: none !important;
      border: 1px solid $gray !important;
      margin-top: 1.8vh !important;
      margin-bottom: 10px;

      svg {
        margin-left: 0 !important;
      }
    }
  }


  .filters-content-wrapper {
    margin-top: 7vh;

    .filter-by, .filter-by-date {
      margin: 15px;
      float: left;
    }
  }

  @media (min-width: $width-xs) and (max-width: $width-xl) {

    .filters-toggle, .filters-aside, #a35399e4nxbackdrop, #m72xhtoa1vbackdrop {
      display: block !important;
      margin-left: 8px;
      padding: 4px 11px;
    }
    .header-label {
      display: block;
      float: left;
    }

    .filters-toggle {
      &__btn {
        float: right;
      }
    }
  }

}
