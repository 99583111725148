@import "src/iensoink/ui-components/scss/variables";

.info-cell-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  font-size: 12px !important;

  .ienso-tooltip {
    padding-left: 10px;
  }

  &__alert {
    color: $red !important;

    &__icon {
      margin-left: 6px;
      height: 17px;
      width: 17px;
    }

    &__hidden {
      display: none;
    }
  }

  &__warning {
    color: $orange !important;

    &__icon {
      margin-left: 6px;
      height: 17px;
      width: 19px;
    }

    &__hidden {
      display: none;
    }
  }
}

@media screen and (max-width: 1500px) {
  .info-cell-container {
    flex-direction: column;
    height: 50px;
    align-items: flex-end;

    &__alert {
      margin-right: 1px;
    }
  }
}
