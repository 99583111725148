@import "src/iensoink/ui-components/scss/variables";

.ienso-multi-selection-categories {
  &__buttons-wrapper{
    margin-top: 7px;
    button{
      box-shadow: none!important;
      outline: none!important;
      font-size: 11px;
    }
    .select-all-btn{
      color: rgb(240, 163, 230);
      float: left;
      padding: 6px 16px!important;
    }
    .clear-btn{
      float: right;
      color: $black;
    }
  }


  button.dropdown-toggle {
    width: max-content;
    min-width: 142px;
    text-align: left;
    font-weight: 400;
    height: 33px!important;
    box-shadow: none!important;
    border: 1px solid $gray3!important;
    border-radius: 4px!important;
    color: $black;
  }

  .show {
    button.dropdown-toggle {
      border: 1px solid $black!important;
    }
  }

  button.dropdown-toggle:hover {
    border: 1px solid $black!important;
    color: $black!important;
  }

  button.dropdown-toggle::after {
    float: right;
    margin-top: 9px;
  }

  .dropdown-menu {
    padding: 17px 17px 13px 0 !important;
    border-radius: 10px;
    box-shadow: none;
    margin-top: 10px!important;
    min-width: 215px;

    .has-sub-menu {
      .dropdown-item:hover {
        background-color: $offWhite;
      }
    }

    .has-sub-menu:hover{
        background-color: unset;
    }

    .dropdown-item {
      padding: 0;

      span.opened-sub-menu{
        transform: rotate(180deg);
        transform-origin: 50% 50%;
      }

      .ienso-checkbox {
        display: inline-block;
        margin-right: 7px;
      }

      &.active,
      &:active {
        background-color: transparent;
        color: $red;
      }

      .item-expand {
        float: right;
        line-height: 24px;
        font-weight: bold;
        padding: 0 10px 0 10px;
      }

      span.item-label {
        padding: 7px 0 7px 16px;
        line-height: 24px;
        width: 100%;
        cursor: pointer;

        .item-label {
          padding: 7px 0 7px 20px
        }
      }
    }
  }


}
.ienso-multi-selection-categories.selected{
  .dropdown-toggle{
    color: $black;
    border-color: $black !important;
  }

  .dropdown-toggle:hover{
    border-color: $black !important;
  }
}
