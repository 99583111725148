@import "src/iensoink/ui-components/scss/variables";


.ienso-empty-img-container{
  width: fit-content;
  display: inline-block;
  &__wrapper{
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eeeeee;
    border-radius: 50%;
  }
}
