@import "src/iensoink/ui-components/scss/variables";

.ienso-input-group {
  label {
    display: flex;
    align-items: flex-end;

    .ienso-tooltip {
      padding-left: 10px;
    }
  }

  input#file-upload-button {
    display: none;
  }

  input:-webkit-autofill {
    -webkit-background-clip: text;
  }

  // hover on regular text input
  .input-group:hover {
    input {
      border-color: $black2;
    }
  }


  // hover on dropdown--
  .input-group:hover .ienso-select {
    border-color: $black2;
  }

  //// hover on input with icon--
  .input-group:hover .input-group-prepend .input-group-text {
    border-color: $black2;
  }
  // -----------------------------

  // red border on dropdown with icon on error--
  .input-group-text.text-white.input-group-text-invalid {
    border-color: $red  !important;
    border-right: none !important;
    font-size: 13px !important;
  }
  // -------------------------------------------

  // red border on dropdown with icon on error--
  .input-group-text.text-white.input-group-text-invalid1 {
    border-color: $red  !important;
    border-right: none !important;
    font-size: 13px !important;
  }
  // -------------------------------------------


  .form-control {
    border: 1px solid $gray3;
    box-shadow: none;
    padding-left: 10px;
  }

  .form-control.is-invalid {
    border-color: $red  !important;
    box-shadow: none;
    font-size: 13px !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e55353' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath strokeLinejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e55353' stroke='none'/%3e%3c/svg%3e") !important;
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  .invalid-feedback {
    font-size: 13px !important;
    color: $red  !important;
  }

  input::placeholder {
    color: $gray2  !important;
  }
}

.ienso-input-group.group-disabled {
  input.form-control {
    background-color: $gray3 !important;
    color: $gray2;
  }

  input::placeholder {
    color: $gray2;
  }
}

.ienso-input-group:focus-within {
  input {
    border-color: $black2 !important;
  }

  .input-group-text {
    border-color: $black2 !important;

  }
}