@import "_variables.scss";

/* m - medium
   b - bold
   r - regular
   l - light
*/

/*Mega font-m-35*/
.text-mega {
  font-size: 35px !important;
  font-weight: 500 !important;
  color: $black;
}

/*Subheading 2 font-b-21*/
.text-subheading-2 {
  font-size: 21px !important;
  font-weight: bold !important;
  color: $black;
}

/*Subheading 1 font-r-21*/
.text-subheading-1 {
  font-size: 21px !important;
  color: $black;
}

/*Callout font-m-18*/
.text-callout {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: $black2;
}

/*Tabs font-r-15*/
.text-tabs {
  font-size: 15px !important;
  color: $black;
}

/*Body 1 font-r-14*/
.text-body-1 {
  font-size: 14px !important;
  color: $black;
  font-weight: 400!important;
}

/*Body 2 font-m-14*/
.text-body-2 {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: $black;
}

/*Body 3 font-l-14*/
.text-body-3 {
  font-size: 14px !important;
  font-weight: 300 !important;
  color: $black;
}

/*Errors font-r-13*/
.text-errors {
  font-size: 13px !important;
  color: $black2;
}

/*Small 1 font-r-12*/
.text-small-1 {
  font-size: 12px !important;
  color: $black;
}

/*Tiny font-r-11*/
.text-tiny {
  font-size: 11px !important;
  color: $black;
}
