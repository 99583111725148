@import "src/iensoink/ui-components/scss/variables";

.ienso-button {
  width: fit-content;
  height: fit-content;
  display: inline-block;
  z-index:1;
  button:focus{
    box-shadow: none;
    outline: none;
  }
  button.secondary.lg,button.main.lg{
    width: 210px;
  }

}

