@import "src/iensoink/ui-components/scss/variables";

.ienso-checkbox {

  label{
    margin-left: 29px;
    display: inline-block;
    color: $black2;
    margin-top: 2px;
  }

  &__checkbox-input {
    position: absolute;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
    width: 19px;
    height: 19px;
  }

  &__checkbox {
    &--filled {
      display: block;
      position: absolute;
      color: $white;
      background-color: $main;
      border-radius: 4px;
      width: 19px;
      height: 19px;
      padding: 5px;
    }

    &--empty {

      display: block;
      position: absolute;
      border-radius: 4px;
      background-color: $white;
      color: $white;
      width: 19px;
      height: 19px;
      border: solid 1px $main;
      cursor: pointer;
    }
  }

  &__checkbox-input:checked {
    & ~ .ienso-checkbox__checkbox--filled {
      display: block;
    }

    & ~ .ienso-checkbox__checkbox--empty {
      display: none;
    }
  }

  &__checkbox-input:disabled {

    & ~ .ienso-checkbox__checkbox--filled {
      background-color: $gray3;
    }

    & ~ .ienso-checkbox__checkbox--empty {
      background-color: $white;
      color: $gray2;
      border: solid 1px $gray2;
    }
  }
}
