@import "src/iensoink/ui-components/scss/variables";

.ienso-modal-window {

  .modal.sm {
    .modal-dialog {
      width: 531px !important;
      max-width: 531px !important;
      min-width: 531px !important;

    }
  }

  .modal.md {
    .modal-dialog {
      width: 686px !important;
      max-width: 686px !important;
      min-width: 686px !important;
    }
  }

  .modal.lg {
    .modal-dialog {
      width: 772px !important;
      max-width: 772px !important;
      min-width: 772px !important;
    }
  }

  .modal-dialog {
    border-radius: 10px;

    .modal-content {
      border-radius: 14px 14px 10px 10px;

      .container {
        padding: 0;
        box-shadow: 0 4px 20px 0 $black;
        border-radius: 10px;
        &__header {
          background-color: $black;
          height: 68px;
          display: table;
          width: 100%;
          border-radius: 10px 10px 0 0;
          padding: 22px;
          border: solid 1px $offWhite;

          span.title {
            color: $white;
            display: table-cell;
            vertical-align: middle;
          }

          button.close-btn {
            float: right;

            svg {
              fill: $white;
              width: 17px;
              height: 17px;
            }
          }
        }

        &__body {
          padding: 30px;
        }

        &__footer {
          border: none;
          padding: 0 20px 20px 0!important;

          .btn-wrapper {
            button {
              margin: 10px 10px;
            }

            &__btn-right {
              background-color: $main;
            }
          }
        }
      }
    }
  }
}
