@import "src/iensoink/ui-components/scss/variables";

.ienso-input-group.input-rounded-border-right {
  input,.ienso-select {
    border-radius: 5px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

  }
}

.ienso-input-group.input-rounded-border {
  input,.ienso-select {
    border-radius: 5px !important;
  }

}


html body .ienso-inputs-group {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input,select {
    border-left: 1px solid $gray;
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
    height: 33px !important;
  }

  .text-field-icon {
    margin: 0 auto;
    vertical-align: middle;
  }

  .form-group {
    label {
      font-size: 14px;
      color: $black2;
      margin-bottom: 13px;
      width: 100%;
    }

    margin-bottom: 21px;
  }

  .input-group {
    input.form-control {
      -webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important;
      height: 33px !important;
      color: $black !important;
    }


    .text-white {
      color: $black !important;
      padding: 0 !important;

      .text-field-icon {
        svg {
          width: 21px !important;
          height: 18px !important;
          margin: 0 auto;
          fill: $gray;
        }
      }
    }
  }

  .input-group-text {
    background-color: $offWhite;
    border-color: $gray3;
    width: 39px;
    height: 33px;
  }


  .input-group-prepend {
    width: 39px !important;
    height: 33px !important;
  }


}

.ienso-inputs-group.group-disabled {
  .form-group {
    label {
      font-size: 14px;
      color: $black2 !important;
    }

    // no hover effect
    .input-group:hover {
      input {
        border-color: $gray3;
      }
    }
    
    // no hover effect
    .input-group:hover .input-group-prepend .input-group-text {
      border-color: $gray3;
    }

    .input-group {
      .form-control{
        background-color: $gray3 !important;
        color: $gray2 !important;
      }
    }
  }

  input::placeholder {
    color: $gray2 !important;

  }


  border-color: $gray2 !important;

  svg {
    fill: $gray5 !important;
  }

  input.form-control {
    background-color: $white;
    color: $gray5 !important;
  }

  input.color-view {
    opacity: 0.1;
  }


}
