@import "src/iensoink/ui-components/scss/variables";


.ienso-button {

  %secondary {
    height: 35px;
    border: solid 1px $main  !important;
    border-radius: 4px !important;
    color: $main  !important;
    box-shadow: none !important;
  }


  button.lg.secondary-with-icon {
    width: 230px;
  }

  button.secondary {
    @extend %secondary;
    min-width: 110px;
  }

  .secondary-with-icon {
    @extend %secondary;
    border-radius: 4px;
    min-width: 130px;

    svg,
    img {
      width: 16px;
      height: 17px;
      margin-right: 10px;
      fill: $main;
    }
  }

  .secondary-icon-only {
    width: 35px;
    height: 35px;
    padding: unset;
    border-radius: 4px;
    border: solid 1px $main  !important;
    box-shadow: none !important;

    svg,
    img {
      width: 11px;
      height: 13px;
      fill: $main;
    }
  }

  button.secondary,
  .secondary-with-icon,
  .secondary-icon-only {
    &:hover {
      box-shadow: 0 2px 7px 0 $gray  !important;
    }

    &:disabled {
      border: none !important;
      background-color: $gray3  !important;
      color: $gray2  !important;
      box-shadow: none !important;

      svg,
      img {
        fill: $gray2;
      }
    }
  }
}