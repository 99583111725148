@import "src/iensoink/ui-components/scss/variables";


.ienso-tabs {

  ul.tab-cnav-custom {
    border: none;

    width: 100%;
    margin-bottom: 20px;

    a:hover {
      border: none;
    }

    a {
      min-width: 8vw;
      text-align: center;

      span {
        font-size: 15px !important;
        color: $gray;
      }

      fill: $gray;

      svg {
        margin-right: 10px;
        margin-bottom: 2px;
        fill: $gray;
      }
    }

    a.active {
      border-right: none;
      border-left: none;
      border-top: none;
      background-color: inherit !important;
    }

    a.nav-link.active:hover {
      span {
        color: $black;

        svg {
          fill: inherit !important;
        }
      }
    }

    a.nav-link:hover {
      span {
        color: $black;

        svg {
          fill: $black !important;
        }
      }
    }

  }

  a.nav-link.disabled{
    span {
      color: $gray2;

      svg {
        fill: $gray2 !important;
      }
    }
  }

  .nav-tabs, .nav-link {
    border: none !important;
  }

  @media only screen and (min-width: $width-xs) {

  }
}
