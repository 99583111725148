@import "src/iensoink/ui-components/scss/variables";

.ienso-table-footer {
  width: 100%;
  height: auto;
  margin-top: 20px;
}




@media (min-width: $width-xs) and (max-width: $width-lg) {
  .ienso-table-footer {
    text-align: center;
  }
}
