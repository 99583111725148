@import "src/iensoink/ui-components/scss/variables";

.item-custom-text{
  color: $gray !important;
}

.ienso-table-actions-button {
  .actions-table-dropdown {
    transform: translate(-52px, 0px) !important;

    svg {
      margin-right: 13px;
    }
  }

  .btn-container {
    padding: 0;

    button {
      background-color: transparent;
      box-shadow: none !important;
      outline: none;
      padding: 2px !important;
      width: 3.5vw !important;
    }

    button:hover, button:focus, button:visited, button:active, .btn-secondary, .dropdown-toggle {
      background-color: transparent !important;
    }

    .dropdown {
      float: right;

      .dropdown-toggle::before {
        display: none !important;
      }

      .menu-header{
        padding-bottom: 12px !important;

      }
      .dropdown-menu {
        box-shadow: 0 10px 30px 0 rgba(206, 213, 239, 0.5);

        padding: 20px 14px;
        border-radius: 10px;
        background-color: $white;
        width: 219px;

        a.dropdown-item {
          padding: 13.5px 6px;
          span{
            font-size: 14px!important;
          }
          svg{
            width: 15px!important;
            height: 15px!important;
            fill: $gray;
          }
        }
        a.dropdown-item:active{
          background-color: $gray3!important;
        }
        a.dropdown-item:hover{
          background-color: $gray3;
        }
        .menu-header {
          background-color: transparent !important;
          padding: 0 6px;
        }
      }
    }
  }


}
