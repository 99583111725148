@import "src/iensoink/ui-components/scss/variables";


.ienso-button {
  button.modal-main {
    height: 30px;
    box-shadow: none;
    color: $white !important;
    border-radius: 6px;
    padding: 0;
  }

  button.modal-main.sm {
    width: 110px !important;
  }

  button.modal-main.lg {
    width: 170px !important;
  }

  button.modal-main, .modal-main-with-icon, .modal-main-icon-only {
    &:hover {
      box-shadow: 0 2px 7px 0 $gray !important;
    }

    &:disabled {
      background-color: $gray3 !important;
      color: $gray2 !important;
      box-shadow: none !important;
    }
  }
}
