@import "src/iensoink/ui-components/scss/styles";

.info {
  
  .label {
    margin-right: 10px;
  }

  .connected {
    color: $green !important;
  }

  .disconnected {
    color: $red;
  }

}