@import "src/iensoink/ui-components/scss/variables";


.ienso-toaster {

  .toast-body{
    font-size: 14px;
    white-space: nowrap;
    width: 887px!important;
    height: 54px;
    display: table-cell;
    vertical-align: middle;
  }
  .toaster {
    width: 887px!important;
    .toast{
      border-radius: 6px;
      height: 54px;

    }
  }

  &__toast.success {
    background-color: $green;

    .toast-body {
      color: $white;
    }
  }

  &__toast.danger {
    background-color: $red;

    .toast-body {
      color: $white;
    }
  }

  &__toast.warning {
    background-color: $orange;

    .toast-body {
      color: $black;
    }
  }
}
