@import "src/iensoink/ui-components/scss/variables";

.ienso-checkbox-brand {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;

  &__checkbox-input {
    position: absolute;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
    height: 38px;
    width: 38px;
  }

  &__checkbox {
    &--filled {
      display: block;
      position: absolute;
      color: $white;
      background-color: $blue;
      border-radius: 50px;
      height: 38px;
      width: 38px;
      padding: 13px;
    }

    &--empty {
      display: block;
      position: absolute;
      border-radius: 50px;
      background-color: $white;
      color: $white;
      height: 38px;
      width: 38px;
      border: solid 1px $gray;
      cursor: pointer;
    }
  }

  &__checkbox-input:checked {
    & ~ .ienso-checkbox-brand__checkbox--filled {
      display: block;
    }

    & ~ .ienso-checkbox-brand__checkbox--empty {
      display: none;
    }
  }

  &__checkbox-input:disabled {

    & ~ .ienso-checkbox-brand__checkbox--filled {
      background-color: $gray3;
    }

    & ~ .ienso-checkbox-brand__checkbox--empty {
      background-color: $white;
      color: $gray2;
      border: solid 1px $gray2;
    }
  }
}
