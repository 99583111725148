@import "src/iensoink/ui-components/scss/variables";

.upload-img {
  margin-bottom: 8vh;
  width: 320px;

  .header-label {
    font-size: 14px;
    font-weight: 500;
  }

  &__container {
    width: 240px;
    height: 240px;
    border-radius: 10px;
    box-shadow: 0 10px 40px 0 rgba(52, 62, 76, 0.1);
    margin-top: 5.5vh;
    margin-bottom: 1vh;


    &__up-side {
      height: 165px;
      width: 100%;
      border-radius: 10px 10px 0 0;
      padding: 20px 12px;

      .container {
        width: 100%;
        height: 100%;
        text-align: center;
        padding: 0;

        .ienso-loader {
          margin-top: 0 !important;
          height: 133px;
        }

        .preview-wrapper {
          width: 100%;
          height: 133px;
          overflow: hidden;
          position: relative;
          .preview {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            max-width: 100%;
            max-height: 100%;
          }

        }


        .upload-icon {
          object-fit: cover;
          position: absolute;
          top: 25%;
          left: 44%;
          transform: translate(-50%, -50%);
          width: 40%;
          height: auto;
        }

        span {
          font-size: 13px;
          font-weight: 300;
          color: $gray;
        }
      }
    }

    &__down-side {
      height: 75px;
      width: 100%;
      background-color: $offWhite;
      border-radius: 0 0 10px 10px;

      .container {
        height: 100%;
        width: 100%;
        padding: 10px 15px;

        &__border {
          height: 54px;
          width: 100%;
          background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='%23768192FF' stroke-width='1' stroke-dasharray='6' stroke-dashoffset='4' strokeLinecap='butt'/%3e%3c/svg%3e");
          border-radius: 5px;

          &__wrapper {
            padding: 10px;
            display: table;
            width: 100%;
            height: 54px;

            span {
              display: table-cell;
              vertical-align: middle;
            }

            .btn-wrapper {
              float: right;
              width: fit-content;
              .ienso-button {
                float: right;

                .upload-btn {
                  background-color: $blue;
                  box-shadow: none;

                  img {
                    width: 15px;
                    height: 15px;
                  }

                }

                .remove-btn {
                  border: none !important;

                  img {
                    width: 15px;
                    height: 15px;
                  }
                }
              }
            }
          }
        }

      }

    }

  }
  .text-format-img{
    color: $gray!important;
  }
  &__upload-button {
    margin: 27px 0 13vh;
    padding: 9px 0;
    border-radius: 6px;
    background-color: #6b6e80;
    color: $white;
    width: 100%;

    img {
      margin-right: 1vw;

    }
  }

}
