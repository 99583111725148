@import "src/iensoink/ui-components/scss/variables";

html body .ienso-header-layout {
  width: 100%;
  height: auto;

  .c-header-nav-items {
    background-color: $white;
    border-radius: 100%;

    a {
      padding: 2px !important;
    }
  }

  .c-header,
  .c-header-light,
  .c-header-fixed {
    padding: 0 0 0 15px !important;

  }

  .notifications-badge {
    width: 9px;
    height: 9px;
    background-color: $red !important;
    position: absolute;
    border-radius: 100%;
    top: 0;
    right: 5px;
  }

  .dropdown-item:hover,
  .dropdown-item:active{
    background-color: $gray2!important;
  }


  .c-header,
  .c-header-light,
  .c-header-fixed,
  .c-header-with-subheader,
  .c-subheader {
    background-color: $black;
    border-top: 0 !important;
    color: $paleBlue;
    box-shadow: none !important;
    border: none;
    height: fit-content;
    min-height: unset;

    ul {
      margin-top: -2px;
    }

  }

  ol{
    height: fit-content;
    min-height: unset;
  }
  ol:last-child {
    li {
      color: $white !important;
    }
  }

  .bottom-divider {
    height: 70px;
    width: 100%;
    background-color: $black;
    position: absolute;
    margin-top: -2px;
    left: 0;
    right: 0;
  }

  .breadcrumb-item::before {
    color: var(--brand-color) !important;
    padding-right: 11px !important;
  }

  .breadcrumb-item {
    padding-left: 11px !important;
  }

  a,
  .breadcrumb-item,
  .breadcrumb-item,
  .breadcrumb-item::before {
    color: $paleBlue;
  }


  .toggle-btn-wrapper {
    border-radius: 50%;
    margin: 27px 14px 47px 14px;

    .c-header-toggler-icon {
      margin-right: 1.5px;
    }

    .c-header-toggler {
      margin: 2px auto !important;
    }
  }

  .c-avatar {
    width: 27px;
    height: 27px;

    .c-avatar-img {
      width: 100%;
      height: auto;
    }
  }

  @media only screen and (max-width: $width-sm) {
    .c-subheader, ul {
      display: none;
    }

    .bottom-divider {
      background-color: transparent;
    }
  }

}
