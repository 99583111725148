@import "src/iensoink/ui-components/scss/variables";

%equal-nav-hover-colors {
  background-color: $gray3;
  box-shadow: $navigation-box-shadow;
}


html body .iensoNavigation {
  .c-sidebar {
    position: static !important;
  }

  display: flex;
  flex-direction: row;
  min-height: 94vh !important;
  box-shadow: none;
  height: auto !important;


  .c-sidebar-minimizer {
    background-image: $navigation-link-hover-background;
  }

  .c-sidebar, .c-sidebar-dark, .c-sidebar-lg-show, .c-sidebar-fixed {
    box-shadow: none !important;
  }

  .c-sidebar {
    box-shadow: none;

    .c-sidebar-brand {
      background-color: $white;
    }

    background-color: $white;

    ul {
      margin: auto 15px auto 10px;

      li {
        border-radius: 10px;

        a {
          border-radius: 10px;

          .navigation-icon-wrapper {
            width: 46px !important;
          }
        }
      }
    }

    .c-sidebar-nav-link,
    .c-sidebar-nav-dropdown,
    .c-sidebar-nav-dropdown-toggle,
    .c-sidebar-nav-link.c-active,
    .c-sidebar,
    .c-sidebar-nav-dropdown.c-show,
    .c-sidebar-nav-link,
    .c-sidebar-nav-icon {
      color: $gray;
      transition: none;
      font-size: 14px;
      background-color: $white;
    }



    ul {
      li.active-navigation-item {
        background-color: $offWhite;



        a.c-sidebar-nav-link.c-active {
          background-color: $offWhite !important;
          color: $black !important;
        }
      }
    }
    //======== HOVER =======
    ul {
      li:hover {
        background-image: $navigation-link-hover-background;

        .c-sidebar-nav-link,
        .c-sidebar-nav-link.c-active,
        .c-sidebar-nav-dropdown,
        .c-sidebar-nav-dropdown-toggle {
          @extend %equal-nav-hover-colors;
          flex-grow: 0;
          box-shadow: none;
          // transform: scale(1.08);
          transition: all .2s ease-in-out;

          .c-sidebar-nav-icon {
            background-color: $gray3;
          }
        }
      }
    }


    .c-sidebar-nav-link:active,
    .c-sidebar-nav-link.c-active:active,
    .c-sidebar-nav-dropdown:active,
    .c-sidebar-nav-dropdown-toggle:active {
      @extend %equal-nav-hover-colors;
      flex-grow: 0;
      box-shadow: none;
      background-color: $blue;

      .c-sidebar-nav-icon {
        background-color: transparent;
      }
    }
  }
}


