// If you want to override variables do it here
@import "src/iensoink/ui-components/scss/styles";

// Import CoreUI styles
@import "~@coreui/coreui-pro/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

.MuiPickersPopper-root {
    button {
        outline: none !important;

        :focus {
            background-color: #636f83 !important;
        }
    }
}

.Mui-selected {
    outline: none !important;
    background-color: #636f83 !important;
}

.MuiClock-pin {
    background-color: #636f83 !important;
}

.MuiClockPointer-root {
    background-color: #636f83 !important;
}
