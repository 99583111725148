@import "src/iensoink/ui-components/scss/variables";

html body .ienso-table-body {
  min-height: 503px;
  width: 100%;
  margin: 10px 0 50px;
  background-color: $offWhite;
  border: solid 1px $gray3;


  .table {
    thead {
      tr {
        background-color: $white;
      }
      th {
        white-space: nowrap;
        overflow: hidden;
        svg {
          height: 11px;
          margin-left: 4px;
          right: auto;
        }
      }
    }

    tbody {
      tr {
        td {
          .online-status-color__connected {
            color: $green !important;
          }

          .online-status-color__offline {
            color: $red !important;
          }

          .online-status-color__unregistered {
            color: $gray !important;
          }
        }
      }
    }
  }


  //=========================================================

    .dataTable-content {
      width: 100%;
      min-height: 456px!important;

      .tdButtons {
        float: right !important;
      }

      .ienso-table-actions-button-delete{
        button{
          box-shadow: none!important;
        }
      }
      .centered-empty-view {
        text-align: center !important;
        margin-top: 25vh !important;
      }

      .border {
        border: none !important;
      }

      .table {
        margin-bottom: 0;
        th.text-align-left {
          text-align: left;
        }
        th.text-align-center{
          text-align: center!important;
        }
        th {
          border: none;
          text-align: center;

          .d-inline {
            font-size: 14px;
            font-weight: 500;
            color: $black;
          }
        }





        tbody {
          tr:hover {
            background-image: $navigation-link-hover-background !important;
          }

          tr:hover {
            background-color: $offWhite !important;
          }

          tr:nth-child(odd) {
            background-color: $offWhite;
          }

          tr:nth-child(even) {
            background-color: $white;
          }
          td.text-align-left {
            text-align: left;
          }
          td.text-align-center{
            text-align: center!important;
            span{
              text-align: center!important;
            }
          }


          tr {
            line-height: 28px;
            text-align: center;
            td, span {
              overflow-y: visible;
              font-size: 12px;
              color: $black;
              white-space: nowrap;
            }

            th, td {
              border: none !important;
              vertical-align: middle;
              line-height: 28px;

              .td-container {
                width: 100%;
                height: 50px;
              }

              .delete-btn-col {
                padding: 0 3px 0 2px !important;
              }
            }
          }
        }
      }

      .table-responsive {
        overflow: visible;
        min-height: inherit;
      }
    }
  }

