@import "src/iensoink/ui-components/scss/variables";

.ienso-table-header {
  width: 100%;
  margin: 1vh 0 0;

  &__bottom-row {
    width: 100%;
    display: table;

    .title, .select-items-per-page {
      display: table-cell;
      vertical-align: middle;
    }
  }
}

.ienso-table-header.hide-header-bottom-row {
  height: 46px;
}
