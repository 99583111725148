//=== MEDIA QUERIES ====
$width-xs: 0 !default;
$width-sm: 576px !default;
$width-md: 768px !default;
$width-lg: 992px !default;
$width-xl: 1200px !default;
$width-xxl: 1400px !default;

//================= COLORS =================
$navigation-box-shadow: 0 10px 30px 0 rgba(185, 185, 185, 0.25) !default;
$space-listbox-shadow: 0 4px 40px 0 rgba(87, 92, 107, 0.14) !default;
$navigation-link-hover-background: linear-gradient(100deg, #f5f9fc 0%, #ecf2fe) !default;
$blue: #00ADB5 !default;
$main: #6b6e80 !default;
$black: #485055 !default;
$black2: #61656B !default;
$gray: #80858A !default;
$gray2: #B7B8B9 !default;
$gray3: #E1E2E4 !default;
$gray4: #E5E7E9 !default; // deleted
$gray5: #C0C1C4 !default; // deleted
$offWhite: #F5F9FC !default;
$white: #FFFFFF !default;
$orange: #FFB93A !default;
$red: #e25b55 !default;
$green: #1caa2c !default;
$green2: #A2DAA9 !default;
$paleBlue: #E1EAFF !default;
