@import "src/iensoink/ui-components/scss/variables";

.ienso-content-card {
  &__wrapper {
    width: 100%;
    min-height: 5vh;
    padding: 20px!important;
    border-radius: 19px;
    box-shadow: 0 10px 60px 0 rgba(206, 213, 239, 0.5);
    background-color: $white;

    &__header.no-header {
      display: none;
    }

    &__header {
      margin-bottom: 5px;

      .arrow-icon {
        cursor: pointer;
        width: 23.9px;
        height: 13.4px;
        margin: -7px 19.1px 0 0;
      }
    }
  }

  &__content {

  }
}
