@import "src/iensoink/ui-components/scss/variables";


.ienso-text-field {
  span.label{
    color: $black2;
    display: inline-block;
    margin-bottom: 13px;
    width: 100%;
  }
  textarea {
    box-sizing: border-box;
    resize: none;
    width: 100% ;
    max-width: 456px;
    height: 84px ;
    border-radius: 4px;
    border: solid 1px $gray3;
    background-color: $white;
    outline: none;

    &:focus, &:hover {
      border-color: $black2!important;
    }
  }

  .form-group{
    margin-bottom: 0!important;
  }
}
