@import "src/iensoink/ui-components/scss/variables";

@media only screen and (min-width: $width-xs) {
  .pagination-wrapper {
    position: unset;
    margin-top: 15px;

    nav {
      ul.pagination {
        border-radius: 10px;
        margin-bottom: 0;

        li.disabled {
          .page-link {
            color: $gray2;
            box-shadow: none!important;
            border: 1px solid $gray3;

          }
        }
      }
    }

    .page-link:focus{
      box-shadow: none!important;
    }
    
    .page-link {
        border: 1px solid $gray3;
        color: $main;
    }

    .page-item.active{
      .page-link {
        background-color: $main;
        border: 1px solid $main;

      }

      a:hover{
        background-color: $main !important;
        border: 1px solid $main;
        color: $white !important;
      }
    } 
    nav {
      .page-link:hover,.page-item:hover{
        background-color: $gray3!important;
        color: $main;

      }
    }
  }
}

