@import "src/iensoink/ui-components/scss/variables";

.ienso-empty-button {
  button {
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
    bottom: 10px;
    padding: 0 !important;
  }
}
.ienso-empty-button.disabled{
  button{
    color: $gray5!important;
  }
}
